import { Component, HostListener, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { UserConfigService } from './services/user-config.service';
import { UserConfig } from './model/reservation.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PolicyDialogComponent } from './pages/cookies-policy/policy-dialog/policy-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { CookiesDialogResult } from './model/cookies.model';
import { LoaderService } from './services/loader.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import Hotjar from '@hotjar/browser';
import { KeycloakService } from 'keycloak-angular';
import { ReservationService } from './services/reservation.service';
import { environment } from '../environments/environment';
import { AirspaceService } from './services/airspace.service';
import { AdhocFormService } from './services/adhoc-form.service';

const hotjarVersion = 6;

@Component({
  selector: 'rqa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [PrimeNGConfig]
})
export class AppComponent implements OnInit {
  translateService = inject(TranslateService);
  router = inject(Router);
  keycloak = inject(KeycloakService);
  config = inject(PrimeNGConfig);
  userConfigService = inject(UserConfigService);
  dialogService = inject(DialogService);
  cookieService = inject(CookieService);
  reservationService = inject(ReservationService);
  airspaceService = inject(AirspaceService);
  adhocFormServive = inject(AdhocFormService);
  userConfig: UserConfig;
  getScreenWidth: number = window.innerWidth;
  getScreenHeight: number = window.innerHeight;
  isMobileDevice: boolean = false;
  visibleMap: boolean = false;
  loading: boolean = false;
  displayMap: boolean;
  displayMapOnDesktop: boolean = true;
  resizeMap: boolean;

  constructor(private loaderService: LoaderService) {
    this.loaderService.loaderState.subscribe((state: boolean) => {
      this.loading = state;
    });
  }

  ngOnInit(): void {
    if (this.cookieService.check('analitycsChecked')) {
      Hotjar.init(environment.siteId, hotjarVersion);
    }
    this.airspaceService.getAirspace().subscribe();
    this.checkIsMobileDevice();
    this.checkActiveRouterLink();
    this.translateService.addLangs(['en', 'pl']);
    this.translateService.setDefaultLang('pl');
    this.getUserConfig();
    this.reservationService.getHolidays().subscribe();
    this.translateService.get('primeng').subscribe((res) => {
      this.config.setTranslation(res);
    });
  }

  onResizeSplitter(): void {
    this.resizeMap = true;
  }

  onVisibleMap(): void {
    this.visibleMap = !this.visibleMap;
    this.resizeMap = true;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.checkIsMobileDevice();
  }

  private checkIsMobileDevice(): void {
    this.isMobileDevice = this.getScreenWidth < 992;
    this.userConfigService.setIsMobileDevice(this.isMobileDevice);
  }

  private getUserConfig(): void {
    this.userConfigService.userConfig$.subscribe((userConfig) => {
      if (userConfig) {
        this.userConfig = userConfig;
        this.translate(userConfig.locale.toLowerCase());
        setTimeout(() => this.showCookiesDialog(), 1000);
      }
    });
  }

  private showCookiesDialog(): void {
    if (!this.cookieService.check('cookiesPolicy')) {
      const ref: DynamicDialogRef = this.dialogService.open(PolicyDialogComponent, {
        header: this.translateService.instant('dialogs.cookiePolicy.header'),
        contentStyle: { overflow: 'auto' },
        width: '50vw',
        modal: false,
        position: 'bottom',
        breakpoints: {
          '960px': '50vw',
          '640px': '90vw'
        },
        baseZIndex: 10000
      });

      if (ref) {
        ref.onClose.subscribe((result: CookiesDialogResult) => {
          if (result?.accepted) {
            this.cookieService.set('cookiesPolicy', 'true', 365, '/', '', true, 'Strict');
            if (result?.analitycsChecked) {
              this.cookieService.set('analitycsChecked', 'true', 365, '/', '', true, 'Strict');
            }
          } else {
            this.keycloak.logout().then(() => this.router.navigateByUrl('/'));
          }
        });
      }
    }
  }

  private checkActiveRouterLink(): void {
    this.router.events
      .pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.visibleMap = false;
        this.displayMap = event.url === '/' || event.url === '/adhoc';
        this.displayMapOnDesktop = event.url !== '/help' && event.url !== '/privacy-policy';
        if (event.url !== '/adhoc') {
          this.adhocFormServive.setAdhocData(null);
        }
      });
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe((res) => this.config.setTranslation(res));
  }
}
