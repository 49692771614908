<rqa-header></rqa-header>
<div class="relative sm:fixed w-full holidays-info">
  <rqa-holidays-info></rqa-holidays-info>
</div>
<div class="loader flex flex-column align-items-center justify-content-center w-full" *ngIf="loading">
  <p-progressSpinner ariaLabel="loading" />
</div>

<!-- DESKTOP -->
<ng-container *ngIf="!isMobileDevice && displayMapOnDesktop">
  <p-splitter [panelSizes]="[55, 45]" (onResizeEnd)="onResizeSplitter()">
    <ng-template pTemplate>
      <section class="left-side pt-5 lg:pr-5 lg:pl-5 w-full pb-6">
        <router-outlet></router-outlet>
      </section>
    </ng-template>
    <ng-template pTemplate>
      <section class="right-side flex w-full">
        <rqa-map [resizeMap]="resizeMap"></rqa-map>
      </section>
    </ng-template>
  </p-splitter>
</ng-container>

<ng-container *ngIf="!displayMapOnDesktop">
  <section class="left-side pt-5 lg:pr-5 lg:pl-5 w-full pb-6">
    <router-outlet></router-outlet>
  </section>
</ng-container>

<!-- MOBILE -->
<ng-container *ngIf="isMobileDevice">
  <div
    class="view-switch flex justify-content-center mb-0 w-full"
    [ngClass]="{ top: !visibleMap, bottom: visibleMap }"
    *ngIf="displayMap"
  >
    <button
      pButton
      [icon]="visibleMap ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
      (click)="onVisibleMap()"
      class="w-full justify-content-center"
    >
      @if (visibleMap) {
        {{ 'map.hideMap' | translate }}
      } @else {
        {{ 'map.showMap' | translate }}
      }
    </button>
  </div>
  <main class="flex lg:flex-row flex-column">
    <section class="w-full py-6" [hidden]="visibleMap">
      <router-outlet></router-outlet>
    </section>
    <section class="w-full" [hidden]="!visibleMap">
      <rqa-map [resizeMap]="resizeMap"></rqa-map>
    </section>
  </main>
</ng-container>
<p-toast position="bottom-center"></p-toast>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
